import styled from "styled-components";
import { Layout } from "antd";
const { Header, Content, Footer } = Layout;

const infoBannerHeight = 57;
const infoBannerHeightMobile = 60;
const infoBannerHeightMobileSmall = 82;

// export const LayoutContainer = styled(Layout)``;
export const LayoutContainer = styled.div``;

export const HeaderContainer = styled(Header)`
  display: block;
  height: ${({ theme }) => theme.layout.header.height}px;
  line-height: unset;
  background: ${({ $isScrolled }) => $isScrolled ? 'white' : 'transparent'} !important;
  padding: 0;
  z-index: 99;
  box-shadow: ${({ $isScrolled, theme }) => 
    $isScrolled ? `0px 3px 6px ${theme.colors.shadow1}` : 'none'};
  position: fixed;
  width: 100%;
  top: ${infoBannerHeight}px;
  left: 0;
  transition: all 0.3s ease-in-out;

  &.ant-layout-header {
    background: ${({ $isScrolled }) => $isScrolled ? 'white' : 'transparent'} !important;
  }

  @media only screen and (max-width: 768px) {
    height: ${({ theme }) => theme.layout.header.heightiPad + 16}px;
    
  }
  @media only screen and (max-width: 560px) {
    display: none;
  }

`;

export const HeaderContainerMobile = styled(Header)`
  height: ${({ theme }) => theme.layout.header.heightMobile + 18}px;
  line-height: unset;
  background: ${({ $isScrolled }) => $isScrolled ? 'white' : 'transparent'} !important;
  padding: 0;
  z-index: 99;
  box-shadow: ${({ $isScrolled, theme }) => 
    $isScrolled ? `0px 3px 6px ${theme.colors.shadow1}` : 'none'};
  position: fixed;
  width: 100%;
top: ${infoBannerHeightMobile}px;
  left: 0;
  transition: all 0.3s ease-in-out;

  &.ant-layout-header {
    background: ${({ $isScrolled }) => $isScrolled ? 'white' : 'transparent'} !important;
  }

  @media only screen and (max-width: 560px) {

  }
`;

export const MainContainer = styled(Content).withConfig({
  shouldComponentUpdate: true,
  componentId: 'MainContainer',
  shouldComponentUpdateForProp: prop => prop !== 'isHomePage'
})`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  padding-top: ${({ theme, $isHomePage }) => $isHomePage ? '0' : `${theme.layout.header.height + 57}px`};

  @media only screen and (max-width: 768px) {
    padding-top: ${({ theme, $isHomePage }) => $isHomePage ? '0' : `${theme.layout.header.heightiPad + 76}px`};
  }

  @media only screen and (max-width: 560px) {
    padding-top: ${({ theme, $isHomePage }) => $isHomePage ? '0' : `${theme.layout.header.heightMobile + 78}px`};
  }
`;

export const ContainerWithMax = styled.div`
  width: 95%;
  max-width: ${({ theme }) => theme.layout.content.maxWidth}px;
  margin: 0 auto;
  background-color: #ffffff;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FooterContainer = styled(Footer)`
  line-height: unset;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0;
  width: 100%;
`;
