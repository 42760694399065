import styled, { css, rgba } from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  padding-right: ${({ theme }) => theme.spacings.spacing_ml};
  padding-bottom: ${({ theme }) => theme.spacings.spacing_s};
  @media only screen and (max-width: 768px) {
    padding-right: ${({ theme }) => theme.spacings.spacing_ml};
    padding-bottom: ${({ theme }) => theme.spacings.spacing_s};
  }
`;

const hoveredStyle = css`
  text-decoration-line: underline;
  text-decoration-color: ${({ $isScrolled, theme }) => 
    $isScrolled ? theme.colors.prune : theme.colors.white};
  color: ${({ $isScrolled, theme }) => 
    $isScrolled ? theme.colors.prune : theme.colors.white};
  text-decoration-thickness: 2px;
  cursor: pointer;
`;

export const MenuText = styled.div`
  ${({ theme }) => theme.components.menuItem.text};
  && {
    color: ${({ $isScrolled, theme }) => $isScrolled ? theme.colors.black : theme.colors.white} !important;
    text-shadow: ${({ $isScrolled }) => $isScrolled ? '2px 2px 2px 2px rgba(255, 255, 255, 0.0)' : '1px 1px 1px rgba(0, 0, 0, 0.8)'};
    transition: all 0.3s ease;
  }
  &:hover {
    ${({ hovered, $isScrolled }) => hovered && hoveredStyle};
  }
`;

export const MenuIconWrap = styled.div`
  ${({ theme }) => theme.components.menuItem.text};
  && {
    font-size: 13px;
    text-align: center;
    align-items: center;
    display: flex;
    padding-top: 4px;
    padding-left: 4px;
    color: ${({ $isScrolled, theme }) => $isScrolled ? theme.colors.black : theme.colors.white} !important;
    transition: all 0.3s ease;
  }
  
  .chevron-down {
    transition: 0.1s cubic-bezier(0.19, 1, 0.19, 1);
    path {
      stroke: ${({ $isScrolled }) => $isScrolled ? '#1F1F1F' : '#FFFFFF'} !important;
      transition: stroke 0.3s ease;
    }
    &.open {
      transform: rotate(180deg);
    }
  }
`;
