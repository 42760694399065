import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: 1000;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: relative;
  z-index: 1001;
`;

export const HamburgerBtn = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: all 0.3s ease;

  img {
    filter: ${({ $isScrolled }) => $isScrolled ? 'none' : 'brightness(0) invert(1)'};
  }
`;

export const PhoneIcon = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;

  img {
    filter: ${({ $isScrolled }) => $isScrolled ? 'none' : 'brightness(0) invert(1)'};
  }
`;

export const NavContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isExpanded }) => isExpanded ? 'translateY(0)' : 'translateY(-100%)'};
  z-index: 999;
`;
