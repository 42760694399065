import React, { useState } from "react";
import PropTypes from "prop-types";
import SubNavMenu from "../SubNavMenu";
import { Container, MenuText, MenuIconWrap, ItemWrap } from "./styled";

const MenuItem = ({ menuType, children, menuIcon, item }) => {
  const [selected, setSelected] = useState(false);

  return (
    <Container
      onClick={() => {
        setSelected(!selected);
      }}
      onFocus={() => {
        setSelected(true);
      }}
      onBlur={() => { setSelected(false) }}
    >
      <ItemWrap data-focused={selected ? "true" : "false"}>
        <MenuText selected={selected}>{children}</MenuText>
        {menuIcon && (
          <MenuIconWrap selected={selected}>{menuIcon}</MenuIconWrap>
        )}
      </ItemWrap>
      {item.hasOwnProperty("children") && (
        <SubNavMenu 
          items={item.children} 
          expanded={selected}
          parentTitle={item.title}
        />
      )}
    </Container>
  );
};

MenuItem.propTypes = {
  menuType: PropTypes.string,
  menuIcon: PropTypes.node,
  children: PropTypes.node,
  item: PropTypes.object,
};

export default MenuItem;
