import React, { useState, useCallback, useEffect, useRef } from "react";
import Link from "next/link";
import MenuItem from "./MenuItem";
import SubMenu from "./SubMenu";
import { Container, MenuWrap } from "./styled";
import { desktopMenus } from "./_nav";

const Menu = ({ $isScrolled }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [submenuPosition, setSubmenuPosition] = useState(null);
  const [isSubMenuHovered, setIsSubMenuHovered] = useState(false);
  const closeTimeoutRef = useRef(null);
  const hoverStateRef = useRef({ menuItem: false, subMenu: false });

  const clearCloseTimeout = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = null;
    }
  };

  const startCloseTimeout = () => {
    clearCloseTimeout();
    closeTimeoutRef.current = setTimeout(() => {
      // Only close if neither menu item nor submenu is hovered
      if (!hoverStateRef.current.menuItem && !hoverStateRef.current.subMenu) {
        setActiveIndex(null);
        setIsSubMenuHovered(false);
      }
    }, 150);
  };

  const handleHovered = useCallback((hover, index) => {
    clearCloseTimeout();
    hoverStateRef.current.menuItem = hover;

    if (hover) {
      setActiveIndex(index);
    } else if (!hoverStateRef.current.subMenu) {
      startCloseTimeout();
    }
  }, []);

  const handleSubMenuHover = useCallback((hover) => {
    clearCloseTimeout();
    hoverStateRef.current.subMenu = hover;
    setIsSubMenuHovered(hover);

    if (!hover && !hoverStateRef.current.menuItem) {
      startCloseTimeout();
    }
  }, []);

  const handlePositionUpdate = useCallback((position) => {
    setSubmenuPosition(position);
  }, []);

  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => clearCloseTimeout();
  }, []);

  return (
    <Container $isScrolled={$isScrolled}>
      <MenuWrap>
        {desktopMenus.map((item, index) => (
          <div key={item.title}>
            {item.type === "parent" ? (
              <MenuItem
                hoverIndex={index}
                hovered={activeIndex === index}
                handleHovered={handleHovered}
                hoveredIndex={activeIndex}
                menuIcon={item.type === "parent"}
                isParentItem={true}
                $isScrolled={$isScrolled}
                onPositionUpdate={handlePositionUpdate}
              >
                {item.title}
              </MenuItem>
            ) : (
              <Link href={item.href}>
                <a>
                  <MenuItem
                    hoverIndex={index}
                    hovered={activeIndex === index}
                    handleHovered={handleHovered}
                    $isScrolled={$isScrolled}
                  >
                    {item.title}
                  </MenuItem>
                </a>
              </Link>
            )}
          </div>
        ))}
      </MenuWrap>

      {activeIndex !== null && desktopMenus[activeIndex]?.type === "parent" && (
        <SubMenu
          childItems={desktopMenus[activeIndex].children}
          hoverIndex={activeIndex}
          handleHovered={handleHovered}
          handleSubMenuHover={handleSubMenuHover}
          $isScrolled={$isScrolled}
          position={submenuPosition}
          isProductsMenu={desktopMenus[activeIndex].title === "Products"}
        />
      )}
    </Container>
  );
};

export default Menu;
