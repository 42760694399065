import React, { useContext, useCallback } from "react";
import SubMenuItem from "./SubMenuItem";
import {
  Container,
  ShadowDiv,
  ContentWrapper,
  SubMenuSection,
  MenuGroupWrap,
} from "./styled";
import MainContainerContext from "../../../context/MainContainer";

function SubMenu({ childItems, handleHovered, handleSubMenuHover, hoverIndex, $isScrolled, position, isProductsMenu }) {
  const { getContainer } = useContext(MainContainerContext);
  const containerWidth = getContainer().offsetWidth;

  const onMouseEnter = useCallback(() => {
    handleSubMenuHover(true);
  }, [handleSubMenuHover]);

  const onMouseLeave = useCallback(() => {
    handleSubMenuHover(false);
  }, [handleSubMenuHover]);

  return (
    <Container
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      containerWidth={containerWidth}
      $isScrolled={$isScrolled}
      position={position}
      isProductsMenu={isProductsMenu}
    >
      <ContentWrapper>
        <SubMenuSection $isScrolled={$isScrolled}>
          <MenuGroupWrap isProductsMenu={isProductsMenu}>
            {childItems &&
              childItems.map((item, key) => (
                <SubMenuItem key={key} item={item} $isScrolled={$isScrolled} />
              ))}
          </MenuGroupWrap>
        </SubMenuSection>
      </ContentWrapper>
      <ShadowDiv isProductsMenu={isProductsMenu} />
    </Container>
  );
}

export default SubMenu;
