import styled from "styled-components";

export const bannerStyle = {
  LIGHT: "light",
  DARK: "dark",
};


export const Column = styled.span`
  display:flex;

  @media only screen and (min-width: 800px) {
    flex-grow:1;
    flex-direction:column;
  }

  `;
export const Row = styled.span`
  display:flex;
  flex-direction:row;
`
export const ClearLeft = styled.span`
  display: flex;
  align-items: center;
  text-align: right;
  gap: 8px;
  
  @media only screen and (min-width: 800px) {
    margin-left: 20px;
    flex-direction: row;
    flex-grow: 1;
  }
  margin-right: 20px;
`

export const ClearRight = styled.span`
  display:flex;
  flex-direction:row;
  text-align:left;
  @media only screen and (min-width:800){

    flex-grow:1;
  }

`

export const Phonelink = styled.a`
  color:#ffffff !important;
    &:active  {color:#ffffff !important}
`

export const Container = styled.div.attrs((props) => ({
  role: "banner",
}))`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  flex-wrap: wrap;
  column-gap: 1px;
  width:100%;
  height:60px;
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  background-color: ${({ theme, type }) =>
    type === bannerStyle.LIGHT
      ? theme.colors.lightGray2
      : theme.colors.darkGray2};
  @media only screen and (max-width: 800px) {
    /* flex-direction: column; */
    padding: 8px;
  }
`;
export const InfoText = styled.span`
  font-family: "Montserrat Medium";
  font-size: 14px;
  color: #ffffff;
  text-align:right;
  vertical-align:text-top;
  &:active {color:white;}
    &:hover {
    cursor: pointer;
  }
  @media only screen and (min-width:800){
width:100%;

  }
  @media only screen and (max-width: 800px) {
    font-size: 14px;
    margin-right: 0px;
    text-align: center;
  }
  @media only screen and (max-width: 430px) {
    width: 100%;
    font-size: 14px;
  }
`;

export const InfoTextLink = styled.div`
  font-family: "Montserrat Medium";
  font-size: 14px;
  margin-right: 4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
  &:active {
    color: #ffffff;
  }

  @media only screen and (max-width: 800px) {
    font-size: 9px;
  }

  @media only screen and (max-width: 414px) {
    font-size: 9px;
  }
`;

export const PhoneNumberText = styled.span.attrs((props) => ({
  // role: "banner",
  // "aria-label": "phonenumber in header",
  // "aria-labelledby": "phonenumber1",
}))`
font - family: "Montserrat Medium";
font - size: 14px;
color: #ffffff;
&:active {color:#ffffff;}

  &:hover {
  cursor: pointer;
  text - decoration: underline;
}

@media only screen and(max - width: 800px) {
  font - size: 14px;
}
`;

export const PhoneIcon = styled.span`
color: ${({ theme, type }) =>
    type === bannerStyle.LIGHT ? theme.colors.blackGreen : theme.colors.white
  };

 & img {
  width: 20px;
  height: 20px;
} 

@media only screen and(max - width: 800px) {
  font - size: 14px;
}
`;
