import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Container, MenuText, MenuIconWrap } from "./styled";

const ChevronImage = (props) => {
  return (
    <span className={props.className}>
      <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 1.5L6 6L10.5 1.5"
          stroke="#1F1F1F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

const MenuItem = ({
  menuType,
  hovered,
  children,
  menuIcon,
  handleHovered,
  hoverIndex,
  isParentItem,
  hoveredIndex,
  $isScrolled,
  onPositionUpdate
}) => {
  const menuItemRef = useRef(null);
  const lastUpdateRef = useRef(null);
  const timeoutRef = useRef(null);

  const updatePosition = useCallback(() => {
    if (!menuItemRef.current || !onPositionUpdate) return;

    const rect = menuItemRef.current.getBoundingClientRect();
    const newPosition = {
      left: rect.left + (rect.width / 2),
      width: rect.width,
      top: rect.bottom
    };

    // Only update if position has changed significantly
    if (!lastUpdateRef.current || 
        Math.abs(lastUpdateRef.current.left - newPosition.left) > 1 ||
        Math.abs(lastUpdateRef.current.top - newPosition.top) > 1) {
      lastUpdateRef.current = newPosition;
      onPositionUpdate(newPosition);
    }
  }, [onPositionUpdate]);

  useEffect(() => {
    if (hovered && isParentItem) {
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      updatePosition();
      window.addEventListener('resize', updatePosition);
      return () => {
        window.removeEventListener('resize', updatePosition);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [hovered, isParentItem, updatePosition]);

  const onMouseEnter = useCallback(() => {
    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    handleHovered(true, hoverIndex);
  }, [handleHovered, hoverIndex]);

  const onMouseLeave = useCallback(() => {
    // Set a small timeout to allow the submenu to be entered
    timeoutRef.current = setTimeout(() => {
      handleHovered(false, hoverIndex);
    }, 50);
  }, [handleHovered, hoverIndex]);

  const onKeyUp = useCallback((e) => {
    if ((e.charCode === 13 || e.type === "click") && !hovered) {
      handleHovered(true, hoverIndex);
    } else if ((e.charCode === 13 || e.type === "click") && hovered) {
      handleHovered(false, hoverIndex);
    }
  }, [handleHovered, hovered, hoverIndex]);

  return (
    <Container
      ref={menuItemRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onKeyUp}
      onKeyUp={onKeyUp}
      tabIndex={0}
      hovered={hovered}
      $isScrolled={$isScrolled}
      aria-label={`${children}, dropdown menu, ${hovered && hoveredIndex === hoverIndex ? "open" : "closed"}`}
    >
      {isParentItem ? (
        <>
          <MenuText hovered={hovered} $isScrolled={$isScrolled}>{children}</MenuText>
          <MenuIconWrap hovered={hovered} $isScrolled={$isScrolled}>
            <ChevronImage className={`chevron-down ${hovered && hoveredIndex === hoverIndex ? "open" : ""}`} />
          </MenuIconWrap>
        </>
      ) : (
        <MenuText hovered={hovered} $isScrolled={$isScrolled}>{children}</MenuText>
      )}
    </Container>
  );
}

MenuItem.propTypes = {
  menuType: PropTypes.string,
  hovered: PropTypes.bool,
  children: PropTypes.node,
  menuIcon: PropTypes.bool,
  handleHovered: PropTypes.func,
  hoverIndex: PropTypes.number,
  isParentItem: PropTypes.bool,
  hoveredIndex: PropTypes.number,
  $isScrolled: PropTypes.bool,
  onPositionUpdate: PropTypes.func
};

export default MenuItem;
