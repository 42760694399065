import styled from "styled-components";
import PButton from "../PButton";

export const Container = styled.div`
  height: 80px;
  width: 100%;
  transition: all 0.4s ease;
  background-color: transparent !important;

  a {
    color: ${({ $isScrolled, theme }) => 
      $isScrolled ? theme.colors.black : theme.colors.white};
  }

  svg path {
    fill: ${({ $isScrolled, theme }) => 
      $isScrolled ? theme.colors.black : theme.colors.white};
    transition: fill 0.3s ease;
  }

  &.ant-layout-header {
    background-color: transparent !important;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 0 ${({ theme }) => theme.spacings.spacing_big};
  background-color: transparent;
  @media only screen and (max-width: 768px) {
    padding: 0 ${({ theme }) => theme.spacings.spacing_xxl_m};
  }
`;

export const ContentWrap = styled.div.attrs((props) => ({
  id: "logo1",
}))`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
`;

export const LogoSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: transparent;

  a {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    gap: 20px;
  }
`;

export const CallUsBtnWrap = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomPButton = styled(PButton)`
  width: 147px;
  @media only screen and (max-width: 768px) {
    width: 109px;
  }
`;